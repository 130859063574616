/** work-around for making standalone builds work in older versions of Nx **/
/** TODO: Remove this when we upgrade to Nx 16.3.0+ **/
import path from 'path'
path.resolve(__dirname, '../next.config.js')

import { Head } from '@cinch-nx/landing-ui-shared'
import Link from 'next/link'
import React from 'react'

export function Index() {
  return (
    <>
      <Head
        fallbackTitle="Cars Without The Faff - cinch"
        fallbackDescription="Welcome to cinch, the faff free way to buy & sell cars. We have thousands of used cars, ready to be sold to you."
      />

      <div
        style={{
          height: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          margin: '2rem auto',
        }}
      >
        <h1 style={{ fontSize: '2rem', marginBottom: '1rem' }}>
          Welcome to Landing Articles!
        </h1>

        <ul className="internalLinkList">
          <li>
            <Link href="/guides" passHref>
              <a href="#">Guides</a>
            </Link>
          </li>
          <li>
            <ul className="internalLinkList">
              <li>
                <Link href="/guides/ask-the-experts" passHref>
                  <a href="#">Ask the Experts</a>
                </Link>
              </li>
              <li>
                <Link href="/guides/car-finance" passHref>
                  <a href="#">Car Finance</a>
                </Link>
              </li>
              <li>
                <Link href="/guides/car-maintenance" passHref>
                  <a href="#">Car Maintenance</a>
                </Link>
              </li>
              <li>
                <Link href="/guides/choosing-a-car" passHref>
                  <a href="#">Choosing a Car</a>
                </Link>
              </li>
              <li>
                <Link href="/guides/electric-cars" passHref>
                  <a href="#">Electric Cars</a>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link href="/news" passHref>
              <a href="#">News</a>
            </Link>
          </li>
          <li>
            <Link href="/announcements/cinch-news" passHref>
              <a href="#">Announcements</a>
            </Link>
          </li>
          <li>
            <Link href="/reviews" passHref>
              <a href="#">Car reviews V2</a>
            </Link>
          </li>
          <li>
            <Link href="/electric-cars" passHref>
              <a href="#">Electric Cars Page</a>
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Index
